import { useMediaQuery, useTheme } from '@material-ui/core';

export const useIsSMDown = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useThemeMode = () => {
  const {
    palette: { type },
  } = useTheme();
  return type;
};

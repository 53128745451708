import React from 'react';
import { useMatch } from 'react-router';
import {
  CrossNavigation,
  LabelType,
  LogoType,
} from '@ankr.com/cross-navigation';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { Search } from 'components/Search';
import { Container } from 'uiKit/Container';
import { useThemeMode } from '../../../../../../common/hooks/themeHooks';
import { PATH } from '../../../../../router/const';
import { useHeaderStyles } from './HeaderStyles';

import '@ankr.com/assets/public/fonts/index.css';
import '@ankr.com/cross-navigation/style.css';

export const Header = () => {
  const classes = useHeaderStyles();
  const match = useMatch(PATH.HOME);
  const isHomePage = !!match;

  const themeMode = useThemeMode();

  return (
    <header
      className={classNames(classes.root, !isHomePage && classes.rootUnderline)}
    >
      <Container className={classes.container} maxWidth={false}>
        <Box className={classes.headerContent}>
          <CrossNavigation
            theme={themeMode}
            logoType={LogoType.SCAN}
            labelType={LabelType.BETA}
            classes={{
              root: classes.logo,
            }}
          />
          {!isHomePage && <Search className={classes.search} />}
        </Box>
      </Container>
    </header>
  );
};
